import { EVENT_TYPES } from './Events.utils';

export const i18n = {
    selectedCountSelectedSelectAll: {
        id: 'configuration-count-selected-select-all',
        defaultMessage: '{selectedCount} of {count} Events Selected',
    },
    allItemsSelected: {
        id: 'all-configuration-count-selected',
        defaultMessage: 'All {selectedCount} Events Selected',
    },
    selectAllItems: {
        id: 'select-all-configuration',
        defaultMessage: 'Select all {count} Events',
    },
    clearAll: {
        id: 'configuration-clear-all',
        defaultMessage: 'Clear All Events',
    },
    paginationLabel: {
        id: 'configuration-events-pagination-type-label',
        defaultMessage: 'Events',
    },
    paginationTitle: {
        id: 'configuration-events-pagination-title',
        defaultMessage: 'Show',
    },
    searchPlaceholder: {
        id: 'configuration-events-search-placeholder',
        defaultMessage: 'Search',
    },
    emptyFilterTitle: {
        id: 'configuration-events-empty-title',
        defaultMessage: 'No Events Found.',
    },
    emptyFilterResults: {
        id: 'configuration-events-empty-message',
        defaultMessage: 'There are no results.',
    },
    createNewTitle: {
        id: 'configuration-events-create-new-title',
        defaultMessage: 'You Have No Events',
    },
    createNewItem: {
        id: 'configuration-events-create-new-message',
        defaultMessage: 'Events will appear here as they are generated.',
    },
};

export const eventTypesI18n = Object.fromEntries(
    Object.values(EVENT_TYPES)
        .filter((x) => x !== EVENT_TYPES.POST)
        .map((x) => [
            x,
            {
                id: `events-data-table-${x}`,
                defaultMessage: x.charAt(0).toUpperCase() + x.slice(1).replace(/_/g, ' '),
            },
        ]),
);
